import { graphql, useStaticQuery } from 'gatsby';

import { getDiscipline } from '../lib/brain';
import redirect from '../lib/redirect';

function path(disciplineName) {
  return `/disciplin/${disciplineName.toLowerCase()}/`;
}

const query = graphql`
  {
    allContentfulDiscipline(filter: { node_locale: { eq: "da" } }) {
      nodes {
        name
        title
      }
    }
  }
`;

export default function ResultRouter() {
  const { disciplineName } = getDiscipline();
  const {
    allContentfulDiscipline: { nodes: disciplines },
  } = useStaticQuery(query);
  if (disciplineName === null) {
    redirect(path('ansvarlighed'));
  } else {
    const { title } = disciplines.find((d) => d.name === disciplineName);
    redirect(path(title));
  }
  return null;
}
